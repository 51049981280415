"use client";
import { Sparkles } from "lucide-react";
import { Button } from "../ui/button";
import { useRouter } from "next/navigation";

export const BuyButton = () => {
  const router = useRouter();
  return (
    <div className='relative'>
      <div className='absolute inset-y-0 w-full bg-[linear-gradient(to_right,#F87BFF,#FB92CF,#FFDD9B,#C2F0B1,#2FD8FE)] blur-md' />
      <Button
        variant={"outline"}
        onClick={() => router.push("/#pricing")}
        className='relative flex w-full justify-start'
      >
        <Sparkles className='mr-2' />
        Passer premium
      </Button>
    </div>
  );
};
