import { type ClassValue, clsx } from "clsx"
import { twMerge } from "tailwind-merge"

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export const blobToBase64 = (blob: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64data = reader.result;
      if (typeof base64data === "string") {
        resolve(base64data);
      } else {
        reject("Failed to convert Blob to Base64");
      }
    };
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};




/**
 * Converts a Base64 string to a Blob.
 * 
 * @param base64Data - The Base64-encoded string.
 * @param contentType - The MIME type of the Blob (e.g., 'audio/mp3', 'image/png').
 * @returns A Blob object.
 */
export const base64ToBlob = (base64Data: string): string => {
  const contentType = "audio/mp3"; // MIME type of the audio data
  const base64String = base64Data.replace(/^data:.+;base64,/, "");
  // Decode Base64 string to binary data
  const byteCharacters = atob(base64String);
  const byteArrays: Uint8Array[] = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const url = URL.createObjectURL(new Blob(byteArrays, { type: contentType }));
  return url;
};


/**
 * Function to use when gpt-3.5-turbo-0125 is called
 * 
 * With 10€ you can buy 1,250,000 tokens
 *
 * Time/Token => 10h/1,250,000 = 0.000008
 *
 * For 10H => 1token= 0.000008 h
 * @param {number} totalTokens -the total tokens
 * @return {number} the time in hours
 */
export const timeToRemoveFromTokens = (totalTokens: number): number => {
  const tokensToHours = totalTokens * 0.000008;
  return tokensToHours;
};

 /**
   * Function to use when tts-1 is called
   * 
   * With 10€ you can buy 666,666,667 caractères
   *
   * Time/caractère => 10h/nb de caractères = 0.000054 secondes par caractère
   *
   * For 10H => 1caractère= 0.000000015 h
   *
   * @param {number} totalChar -the total characters
   * @return {number} the time in hours
   */
  export const timeToRemoveFromCharacters = (totalChar: number): number => {
    const totalTime = totalChar * 0.000000015;
    return totalTime;
  };


 /**
   * Function to use when whisper is called
   * 
   * With 10€ you can buy 1,834.95 minutes
   *
   * Time/min => 10h/nb of minutes = 0.00545 heures par minute
   *
   * For 10H => 1min= 0.00545 heures
   *
   * @param {number} totalMin -the total min
   * @return {number} the time in hours
   */
  export const timeToRemoveFromMinutes = (totalMin: number): number => {
    const totalTime = totalMin * 0.00545;
    return totalTime;
  };


