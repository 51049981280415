"use client";
import { useRef, useEffect } from "react";
import { Provider } from "react-redux";
import { makeStore, AppStore } from "./index";
import { setUser } from "@/core/reducers/user.reducer";
import { UserWithSessions } from "@/actions/user/getUserByEmail";
import { getCurrentUser } from "@/lib/auth"; // Assurez-vous d'importer la fonction

export default function StoreProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const storeRef = useRef<AppStore | null>(null);

  if (!storeRef.current) {
    storeRef.current = makeStore();
  }

  useEffect(() => {
    async function fetchUser() {
      const user = await getCurrentUser();
      if (user) {
        storeRef.current?.dispatch(setUser(user));
        
      }
    }
    
    fetchUser();
  }, []);

  return <Provider store={storeRef.current}>{children}</Provider>;
}
