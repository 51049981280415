import { AppState, UserRole } from "@/store/appState";
import { User } from "@prisma/client";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { deleteCookie } from "@/actions/deleteCookie";
import { z } from "zod";
import {
  LoginSchema,
  NewPasswordSchema,
  RegisterSchema,
  ResetSchema,
} from "@/schemas/auth";
import { login as loginAction, LoginSuccess } from "@/actions/auth/login";
import { register as registerAction } from "@/actions/auth/register";
import { reset as resetAction } from "@/actions/auth/resetPassword";
import { newPassword as newPasswordAction } from "@/actions/auth/new-password";

const initialState: AppState["user"] = {
  id: null,
  name: null,
  image: null,
  role: null,
  email: null,
  remaining_time: null,
  plan: null,
};

export const logout = createAsyncThunk("user/logout", async () => {
  await deleteCookie();
  return { id: null, name: null, image: null, role: null };
});

export const login = createAsyncThunk<
  LoginSuccess,
  z.infer<typeof LoginSchema>,
  { rejectValue: string }
>("user/login", async (values, { rejectWithValue }) => {
  const result = await loginAction(values);

  if (result?.serverError) {
    return rejectWithValue(result.serverError);
  }

  // S'assure que le résultat est du type LoginSuccess
  if (result?.data && "user" in result.data) {
    return result.data as LoginSuccess;
  }

  return rejectWithValue("Une erreur inattendue est survenue");
});
export const register = createAsyncThunk<
  string,
  z.infer<typeof RegisterSchema>,
  { rejectValue: string }
>("user/register", async (values, { rejectWithValue }) => {
  const result = await registerAction({ values });
console.log("res",result)
  if (result?.serverError) {
    console.log("reducer",result.serverError)
    return rejectWithValue(result.serverError);
  }

  // S'assure que le résultat est du type LoginSuccess
  if (result?.data && "success" in result.data) {
    return result.data.success;
  }
console.log("mistake",result)
  return rejectWithValue("Une erreur inattendue est survenue");
});

export const reset = createAsyncThunk<
  string,
  z.infer<typeof ResetSchema>,
  { rejectValue: { error: string } }
>("user/reset-password-email", async (values, { rejectWithValue }) => {
  try {
    const result = await resetAction(values);

    const message = result.message;
    return message;
  } catch (error) {
    if (error instanceof Error) {
      return rejectWithValue({ error: error.message });
    } else {
      return rejectWithValue({ error: "Une erreur inconnue est survenue" });
    }
  }
});
export const newPassword = createAsyncThunk<
  string,
  { data: z.infer<typeof NewPasswordSchema>; token: string },
  { rejectValue: string }
>("user/new-password", async (args, { rejectWithValue }) => {
  const { data, token } = args;
  console.log(args);
  const result = await newPasswordAction({ values: data, token });
  console.log("result", result);
  if (result?.data && "success" in result.data) {
    return result.data.success;
  }
  if (result?.data && "failure" in result.data) {
    throw new Error(result.data.failure);
  }
  if (result && result?.serverError) {
    return rejectWithValue(result.serverError as string);
  }

  return rejectWithValue("Une erreur inattendue est survenue");
});

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.id = action.payload.id;
      state.name = action.payload.name;
      state.image = action.payload.image;
      state.role = action.payload.role;
      state.email = action.payload.email;
      state.remaining_time = action.payload.month_remaining_time;
      state.plan = action.payload.plan;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(logout.fulfilled, (state, action) => {
        return initialState;
      })
      .addCase(login.fulfilled, (state, action) => {
        if ("error" in action.payload) {
          // Reset state if there's an error
        } else {
          const { user } = action.payload;
          state.id = user.id;
          state.name = user.name;
          state.email = user.email;
          state.image = user.image;
          state.role = user.role as UserRole;
          state.remaining_time = user.month_remaining_time;
          state.plan = user.plan as User["plan"];
        }
      })
      .addCase(login.rejected, (state, action) => {
        return state;
      });
  },
});

export const { setUser } = userSlice.actions;
export default userSlice.reducer;
