import { Middleware } from "@reduxjs/toolkit";
import { login, logout, newPassword, setUser } from "@/core/reducers/user.reducer";
import { useRouter } from "next/router";
import { set } from "zod";


const redirectMiddleware: Middleware = (store) => (next) => (action) => {
  const result = next(action);

  if (logout.fulfilled.match(action)) {
    if (typeof window !== "undefined") {
      window.location.href = "/auth/login";
    }
  }
  if (login.fulfilled.match(action)) {
    if (typeof window !== "undefined") {

      window.location.href = "/store";
    }
  }
  if (newPassword.fulfilled.match(action)) {
    setTimeout(() => {
      if (typeof window !== "undefined") {
        window.location.href = "/store";
      }
    }, 5000);
    
  }
  

  return result;
};

export default redirectMiddleware;
